define('helpSites', ['app', 'scriptOnComplete'], function(app, scriptOnComplete) {
  

  var HelpSitePop = function() {

    var self = this;
    self._windowSpecs = 'height=800,scrollbars=yes,width=785';

    self._getLocalePattern = function(locales) {
      var actualLocales = locales && locales.length ? locales : ['NOT-SET'];
      return actualLocales.join('|');
    };

    self._isLocale = function(text, localePattern) {
      return new RegExp(localePattern, 'ig').test(text);
    };

    self._getSelector = function(domains) {

      var actualDomains = domains && domains.length ? domains : [],
        firstEntry = '.js-help-site' + (actualDomains.length ? ', ' : '');
      var reducer = function(acc, value, index, array) {
        var intermediaryAcc = acc + 'a[href*=\'' + value + '\']';

        if (index === array.length - 1) {
          return intermediaryAcc;
        }
        return intermediaryAcc + ', ';
      };

      return actualDomains.reduce(reducer, firstEntry);
    };

    self._getPostFix = function(str) {
      return str.indexOf('?') === -1 ? '?' : '&';
    };

    self._getHelpUrl = function(str, lang, countryCode, customerLocale, localePattern) {

      var postFix = self._getPostFix(str),
        url = str + postFix + 'lang=' + lang;

      return self._isLocale(customerLocale, localePattern) ? url + '-' + countryCode : url;
    };

    self._openHelpSite = function(href, lang, countryCode, customerLocale, localePattern, windowSpecs) {
      var helpUrl = self._getHelpUrl(href, lang, countryCode, customerLocale, localePattern);
      window.open(helpUrl, '', windowSpecs, false);
    };

    self.init = function(siteObj, locales, domains) {

      var lang = siteObj.selectedLang;
      var countryCode = siteObj.countryCode;
      var customerLocale = siteObj.customerLocale;
      var windowSpecs = self._windowSpecs;
      var result = self._getSelector(domains);
      var links = document.querySelectorAll(result);
      var localePattern = self._getLocalePattern(locales);

      app.on('click', function(e) {
        (e.preventDefault) ? e.preventDefault() : e.returnValue = false;
        var href = app.element.getAttribute('href', this);
        self._openHelpSite(href, lang, countryCode, customerLocale, localePattern, windowSpecs);
      }, links);

      scriptOnComplete.write(self._name);
    };

    self._name = 'helpSites';


  };
  var help = new HelpSitePop();
  return help;
});
